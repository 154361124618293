<script setup lang="ts"></script>

<template>
  <div class="figures-container" aria-hidden="true">
    <img src="/images/character-stills_01.png" alt="" />
  </div>
</template>

<style scoped lang="scss">
.figures-container {
  position: relative;

  img {
    position: absolute;
    top: 0;
  }
}
</style>
