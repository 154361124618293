<script setup lang="ts"></script>

<template>
  <div class="figures-container" aria-hidden="true">
    <img src="/images/character-stills_04.png" alt="" />
    <img src="/images/character-stills_02.png" alt="" />
  </div>
</template>

<style scoped lang="scss">
.figures-container {
  position: relative;

  img {
    position: absolute;

    &:nth-child(1) {
      z-index: 2;
      top: 0;
    }

    &:nth-child(2) {
      z-index: 2;
      top: 2rem;
      left: 4rem;
    }
  }
}
</style>
